<template>
  <div class="auth-wrapper auth-v1">
    <v-snackbar v-model="snackbar" :timeout="-1" bottom color="error">
      {{ response_errors.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Lenz Media
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Verification
          </p>
          <p class="mb-2">
            A verification link has been sent to your email address to activate your account.
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <validation-observer ref="observer">
            <v-form>
              <validation-provider v-slot="{ errors }" name="email" rules="required|email">
                <v-text-field
                  v-model="email"
                  :error-messages="errors"
                  required
                  outlined
                  label="Email"
                  placeholder="john@example.com"
                  hide-details
                  class="mb-3"
                ></v-text-field>
              </validation-provider>

              <v-btn color="primary" class="ma-4" outlined @click="resendEmail">
                Resend Verification Link
              </v-btn>
            </v-form>
          </validation-observer>
          <router-link :to="{ name: 'pages-login' }">
            <v-btn color="primary" class="ma-4">
              Login
            </v-btn>
          </router-link>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            New on our platform?
          </span>
          <router-link :to="{ name: 'pages-register' }">
            Create an account
          </router-link>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiExclamationThick } from '@mdi/js';
// eslint-disable-next-line
import { required, email } from 'vee-validate/dist/rules';
// eslint-disable-next-line
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
// eslint-disable-next-line
import util from '@/core/utils/misc';
// eslint-disable-next-line
import Request from '@/core/api/request';

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

extend('email', {
  ...email,
  message: 'The {_field_} must be a working email address',
});

export default {
  name: 'VerifyEmail',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: '',
      icons: {
        mdiExclamationThick,
      },
      snackbar: false,
      response_errors: {},
    };
  },
  methods: {
    // eslint-disable-next-line
    async resendEmail () {
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: 'Wait, while we are verify and send.' });
        await Request.post(`${this.$IYA.URI.RESEND__VERIFICATION}`, {
          email: this.email,
        }).then(response => {
          this.response_errors = {};
          if (response.status === this.$IYA.HTTP.OK || response.status === this.$IYA.HTTP.CREATED) {
            this.$swal('Email verification link has been sent', '', 'success');
          } else {
            this.response_errors = {
              message: response.data.message,
              errors: response.data.errors,
            };
            this.snackbar = true;
          }
        });
        util.isLoading();
      } else {
        console.log('Invalid');
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
